export const colors = {
  blue: 'rgb(0, 82, 156)',
  'blue-rgba-seven': 'rgba(0, 82, 156, 0.7)',
  'blue-rgba-three': 'rgba(0, 82, 156, 0.3)',
  'blue-rgba-one': 'rgba(0, 82, 156, 0.1)',
  red: 'rgb(150, 23, 52)',
  'hlv-red': 'rgb(230, 23, 57)',
  yellow: 'rgb(242, 217, 38)',
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  'light-gray': 'rgb(0, 0, 0, 0.15)',
  gray: 'rgb(0, 0, 0, 0.25)',
};
