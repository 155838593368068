import { LayoutProvider } from '@3pc/layout-components-react';
import React from 'react';
import SEO from './SEO';
import styled from '@emotion/styled';
import { maxWidthVals } from '../styles/container';

const MainTag = styled.main`
  max-width: ${maxWidthVals.pageMaxWidth};
  margin: 0 auto;
  overflow: hidden;
  transform: translateY(20%);
  opacity: 0;
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
  animation-name: site-animation;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
`;

const Layout = ({ children }) => (
  <>
    <SEO />
    <MainTag>
      <LayoutProvider
        theme={{
          colors: {
            blue: 'rgb(0, 69, 230)',
            red: 'rgb(230, 23, 57)',
            yellow: 'rgb(242, 217, 38)',
            black: 'rgb(0, 0, 0)',
            white: 'rgb(255, 255, 255)',
          },
          breakpoints: ['600px', '1024px'],
        }}
      >
        {children}
      </LayoutProvider>
    </MainTag>
  </>
);

export default Layout;
